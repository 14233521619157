<template>
  <dashboard-admin :adminSubjection="adminSubjection" :createStatus="createStatus" @modalAdminSubmit="modalAdminSubmit" :isAdminCMU="isAdminCMU" :ListAllFaculty="ListAllFaculty" @onSelectFac="onSelectFac" @onSelectSemester="onSelectSemester"/>
</template>

<script>
  import DashboardAdmin from '../../components/DashboardAdmin'
  import apiGatewayCpmService from "@/services/apiGatewayService"
  import apiLmsManagementService from "@/services/apiLmsManagementService"
  const config = require(`@/config`)
  import Swal from 'sweetalert2'

  export default {
    name: 'AdminConsole',
    components: {
      DashboardAdmin
    },
    created() {
      Swal.fire({
        text: 'loading...',
        allowOutsideClick: false
      })
      localStorage.setItem('router', true)
      Swal.showLoading()
      this.getAdminCMU(localStorage.getItem('accessToken'))
    },
    mounted () {
      // this.getAdminCMU(localStorage.getItem('accessToken'))
      // this.getCoursesOfUser(localStorage.getItem('accessToken'))
     
    },
    computed: {
      userData: {
          get () {
            return this.$store.state.userData
          },
          set () {
            this.$store.commit('setUserData')
          }
      }
    },
    // beforeRouteLeave (to, from , next) {
    //   const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    //   if (answer) {
    //     next()
    //   } else {
    //     next(false)
    //   }
    // },
    // beforeDestroy () {
    //   window.confirm('Do you really want to leave? you have unsaved changes!')
    // },
    data() {
      return {
        regCourses: [],
        msTeamsList: [],
        lmsList: [],
        ListAllFaculty: [],
        adminSubjection: {},
        filterListSubjection:[],
        isAdminCMU : false,
        createStatus: {
          lms: false,
          msteams: false
        },
        termSelected : config.lmsConfig.currentSemester ,
        facultySelected : {}
      }
    },
    methods: {
      async getAdminCMU(accessToken){
        const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(accessToken);
        if(grantAdmin){
          console.log("grantAdmin" , grantAdmin)
          if(grantAdmin.grantOnCourses.includes("CMU")){
            this.isAdminCMU = true ; 
            const allFaculty = await apiGatewayCpmService.getAllFaculty(accessToken);
            if(allFaculty){
              delete allFaculty.status ;
              this.ListAllFaculty = allFaculty
              
              Swal.close()
            }
          }else{
            this.getCoursesOfUser(localStorage.getItem('accessToken') , this.termSelected)
          }
        }
      },
      async getCourseCMU( query ){
        Swal.fire({
          text: 'loading...',
          allowOutsideClick: false
        })
        Swal.showLoading()
        
        let courselist = await apiGatewayCpmService.getCourseInSubjectionQuery(localStorage.getItem('accessToken') , query)
        console.log("courselist:",courselist)
        if(courselist.status){
          delete courselist.status ;
          console.log("adminSubjection:", courselist)
          this.adminSubjection = courselist
          this.regCourses = courselist
          this.filterListSubjection = []
        }
        Swal.close()

      },
      onSelectFac(queryObj){
        this.facultySelected = queryObj
        const query = `semester=${this.termSelected}&faculty=${queryObj.facultyNumber}`
        this.getCourseCMU(query);
      },
      async onSelectSemester( querySemester){
        this.termSelected = querySemester ; 
        const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem('accessToken'));
        if(grantAdmin){
          if(!grantAdmin.grantOnCourses.includes("CMU")){
            this.getCoursesOfUser(localStorage.getItem('accessToken') , querySemester)
          }else{
            const query = `semester=${querySemester}&faculty=${this.facultySelected?.facultyNumber}`
            this.getCourseCMU(query);
          }
        }
        
      },
      async getCoursesOfUser (accessToken , termSelected) {
        let courselist = await apiGatewayCpmService.getCourseInSubjection(accessToken , termSelected)
        if(courselist.status){
          delete courselist.status ;
          console.log("adminSubjection:", courselist)
          this.adminSubjection = courselist
          this.regCourses = courselist
        }
        Swal.close()
      },
      async getJobStatusMsTeams (msTeamsList) {
        let queryData = ""
        for(let i=0;i<msTeamsList.length;i++){
          queryData = `courseId=${msTeamsList[i].courseid}&mappingId=${msTeamsList[i]._id}`
          let resStatus = await apiGatewayCpmService.getJobs(queryData, localStorage.getItem("accessToken"))
          if(resStatus.length > 0){
            msTeamsList[i].job = resStatus[0]
          }else{
            msTeamsList[i].job = {
              "status": "not found"
            }
          }
        }
        return msTeamsList
      },
      async getJobStatusLms (lmsList) {
        // let lms = {
        //   canvas:[],
        //   moodle:[],
        //   mango:[]
        // }
        for(let i=0;i<lmsList.length;i++){
          switch (lmsList[i].service) {
            case "canvas":
              if(lmsList[i].webUrl !== ""){
                  lmsList[i].job = await apiLmsManagementService.getJobStatus("canvas", lmsList[i].webUrl.split("https://canvas.cmu.ac.th/courses/")[1])
              }else{
                  lmsList[i].job = {
                      job_status: "createing"
                  }
              }
              // lms.canvas.push(lmsList[i])
              break
            case "moodle":
              if(lmsList[i].webUrl !== ""){
                  lmsList[i].job = await apiLmsManagementService.getJobStatus("kcmoodle", lmsList[i].webUrl.split("https://elearning.cmu.ac.th/course/view.php?id=")[1])
              }else{
                  lmsList[i].job = {
                      job_status: "createing"
                  }
              }
              // lms.moodle.push(lmsList[i])
              break
            case "mango":
              if(lmsList[i].webUrl !== ""){
                  lmsList[i].job = await apiLmsManagementService.getJobStatus("mango", lmsList[i].webUrl.split("https://mango.cmu.ac.th/courses/")[1])
              }else{
                  lmsList[i].job = {
                      job_status: "createing"
                  }
              }
              // lms.mango.push(lmsList[i])
              break
            default:
              break
          }
        }
        return lmsList
      },
      onCreateTeamsSubmit (sectionArr,courseId) {
        // console.log("My course onCreateTeamsSubmit:",sectionArr)
        // console.log("My course onCreateTeamsSubmit val2:",courseId)
        // this.$emit('createTeamsSubmit', sectionArr, courseid)
        this.onModalTeamsSubmit(sectionArr,courseId)
      },
      onCreateLmsSubmit (createData,courseId) {
        // console.log(createData,courseId)
        this.onModalLmsSubmit(createData,courseId)
      },
      onCreateSubmit (createData) {
        this.postJobs(createData)
      },
      async onModalTeamsSubmit (val, courseId) {
        console.log("onModalTeamsSubmit:",val,courseId)
        let reqBody = {
          "module":"msteams",
          "service":"msteams",
          "sections":val
        }
        // Swal.fire({
        //   text: 'loading...',
        //   allowOutsideClick: false
        // })
        // Swal.showLoading()
        const createTeamsMapping = await apiGatewayCpmService.createCourseMapping(courseId,reqBody,localStorage.getItem("accessToken"))
        if(createTeamsMapping.status){
          await this.updateTeamsActivate(courseId)
          const createTeams = await this.createTeamsChannel(createTeamsMapping._id)
          if(createTeams.status){
            console.log("after create success:",courseId)
            await this.getCoursesOfUser(localStorage.getItem('accessToken'))
            // Swal.fire({
            //   icon: 'info',
            //   title: 'ระบบกำลังดำเนินการ',
            //   text: 'อาจใช้เวลาสักครู่ในการประมวลผล',
            //   showCancelButton: true,
            //   confirmButtonText: 'ดูสถานะ',
            //   cancelButtonText: 'ปิด',
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     this.$router.push(`/courses/${courseId}/teams`)
            //   }
            // })
          }else{
              this.alertError(createTeams.message)
          }
        }else{
            this.alertError(createTeamsMapping.message)
        }
      },
      async onModalLmsSubmit (val,courseId) {
        console.log("onModalSubmit:",val)
        let reqBody = {
            "module": "lms",
            "service": val.service,
            "sections": val.section
        }

        const createLmsMapping = await apiGatewayCpmService.createCourseMapping(courseId,reqBody,localStorage.getItem("accessToken"))
        if(createLmsMapping.status){
            await this.updateLmsActivate(courseId)
            const createLms = await this.createLms(val, courseId)
            if(createLms.status){
                const resUpdateWebUrl = await this.updateWebUrl(courseId, createLmsMapping._id, createLms.webUrl)
                await this.getCoursesOfUser(localStorage.getItem('accessToken'))
                if(resUpdateWebUrl.status) {
                  console.log(resUpdateWebUrl)
                }
            }else{
                this.alertError(createLms.message)
            }
        }else{
          this.alertError(createLmsMapping.message)
        }
      },
      async createLms (createData, courseId) {
        let resCreate = {}
        let createObj = {}
        let courseData = await apiGatewayCpmService.getCourseDetail(courseId, localStorage.getItem('accessToken'))
        let userData = this.userData
        let semesterSplit = this.getCouseFullSemester(courseData.semester).split("/")
        switch (createData.service) {
          case "canvas":
              createObj = {
                  "term": semesterSplit[0],
                  "year": semesterSplit[1],
                  "term_id": config.lmsConfig.canvas.termId,
                  "term_start": config.lmsConfig.canvas.termStart,
                  "term_end": config.lmsConfig.canvas.termEnd,
                  "regCourse_selected_id": courseData.courseNumber,
                  "regCourse_selected_name": courseData.title,
                  "sectionArr": createData.section,
                  "cmuitaccount_name": userData.email.split('@')[0]
              }
              resCreate = await apiLmsManagementService.createCanvas(createObj)
              if(resCreate['status']) resCreate['webUrl'] = `https://canvas.cmu.ac.th/courses/${resCreate.id}`
              return resCreate
          case "moodle":
              createObj = {
                  "term": semesterSplit[0],
                  "year": semesterSplit[1],
                  "term_start": config.lmsConfig.moodle.termStart,
                  "term_end": config.lmsConfig.moodle.termEnd,
                  "regCourse_selected_id": courseData.courseNumber,
                  "regCourse_selected_name": courseData.title,
                  "category_selected": config.lmsConfig.moodle.termId,
                  "sectionArr": createData.section,
                  "create_by": {
                      "cmuitaccount_name": userData.email.split('@')[0],
                      "cmuitaccount": userData.email
                  }
              }
              resCreate = await apiLmsManagementService.createMoodle(createObj)
              if(resCreate['status']){
                  resCreate = resCreate[0]
                  resCreate['status'] = true
                  resCreate['webUrl'] = `https://elearning.cmu.ac.th/course/view.php?id=${resCreate.id}`
              }
              // if(resCreate['status']) resCreate['webUrl'] = `https://canvas.cmu.ac.th/courses/${resCreate.id}`
              return resCreate
          case "mango":
              createObj = {
                  "term": semesterSplit[0],
                  "year": semesterSplit[1],
                  "term_id": config.lmsConfig.mango.termId,
                  "term_start": config.lmsConfig.mango.termStart,
                  "term_end": config.lmsConfig.mango.termEnd,
                  "regCourse_selected_id": courseData.courseNumber,
                  "regCourse_selected_name": courseData.title,
                  "sectionArr": createData.section,
                  "cmuitaccount_name": userData.email.split('@')[0]
              }
              resCreate = await apiLmsManagementService.createMango(createObj)
              if(resCreate['status']) resCreate['webUrl'] = `https://mango.cmu.ac.th/courses/${resCreate.id}`
              return resCreate
          default:
              break
        }
      },
      async updateWebUrl (courseId, mappingId, url) {
        const reqBody = { 
            "webUrl": url
        }
        const resUpdateWebUrl = await apiGatewayCpmService.updateMapping(courseId, mappingId, reqBody, localStorage.getItem("accessToken"))
        return resUpdateWebUrl
      },
      async postJobs( createData ){
        const profile = await apiGatewayCpmService.getProfile(localStorage.getItem("accessToken"))
        let preData ={
          ...createData,
          "tagName":"admin:"+String(profile.email)
        }
        
        const response = await apiGatewayCpmService.apiPostJobs(localStorage.getItem("accessToken") , preData);
        if(response){
          await this.getCoursesOfUser(localStorage.getItem('accessToken'))
          this.createStatus.msteams = true
          this.createStatus.lms = true
        }else{
          this.alertError(response.message)
        }
      },
      async updateTeamsActivate (courseId) {
        await this.getMsTeamsModule(courseId)
        const reqBody = {
            "activated": true
        }
        for(let i=0;i<this.msTeamsList.length;i++){
            if(!this.msTeamsList[i].activated){
                let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.msTeamsList[i].courseid, this.msTeamsList[i]._id, reqBody, localStorage.getItem("accessToken"))
                if(resUpdateActivate.status){
                    console.log(resUpdateActivate)
                }
            }
        }
        this.getMsTeamsModule(courseId)
      },
      async updateLmsActivate (courseId) {
        await this.getLmsModule(courseId)
        const reqBody = {
            "activated": true
        }
        for(let i=0;i<this.lmsList.length;i++){
            if(!this.lmsList[i].activated){
                let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.lmsList[i].courseid, this.lmsList[i]._id, reqBody, localStorage.getItem("accessToken"))
                if(resUpdateActivate.status){
                    console.log(resUpdateActivate)
                }
            }
        }
        this.getLmsModule(courseId)
      },
      async createTeamsChannel (mappingId) {
          const reqBody = {
              "mode": "createChannelByMapping",
              "mappingId": mappingId,
              "tagName": `add-student-to-teams-${mappingId}`
          }
          const resCreateChannel = await apiGatewayCpmService.createJobs(reqBody, localStorage.getItem("accessToken"))
          return resCreateChannel
      },
      alertError (errorMessage) {
        let text = ""
        switch (errorMessage) {
            case "mappingHasReadyExit":
                text = "course already exist"
                break
            default:
                text = "error"
                break
        }
        Swal.fire({
            icon: 'error',
            title: 'Create fail',
            text: `${text}`,
        })
      },
      async getMsTeamsModule (courseId) {
        const msTeamsList = await apiGatewayCpmService.getCourseMappingList(courseId,"msteams",localStorage.getItem("accessToken"))
        if(msTeamsList){
          this.msTeamsList = msTeamsList
        }
      },
      async getLmsModule (courseId) {
        const lmsList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
        if(lmsList){
          this.lmsList = lmsList
        }
      },
      async onCheckTeamJob (courseId, mappingId) {
        console.log(courseId, mappingId)
        let queryData = `courseId=${courseId}&mappingId=${mappingId}`
        let resStatus = await apiGatewayCpmService.getJobs(queryData, localStorage.getItem("accessToken"))
        if(resStatus){
          console.log(resStatus[0])
          if(resStatus[0].status !== 'teamClassCreated'){
            Swal.fire({
              icon: 'info',
              title: 'ระบบกำลังดำเนินการ',
              text: 'อาจใช้เวลาสักครู่ในการประมวลผล',
              showCancelButton: true,
              confirmButtonText: 'ดูสถานะ',
              cancelButtonText: 'ปิด',
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push(`/courses/${courseId}/teams`)
              }
            })
          }else{
            Swal.fire({
              text: 'loading...',
              allowOutsideClick: false
            })
            Swal.showLoading()
            const resMapping = await apiGatewayCpmService.getCourseMappingOne(courseId,mappingId,localStorage.getItem("accessToken"))
            if(resMapping.status && resMapping[0].webUrl !== ""){
              window.open(`${resMapping[0].webUrl}`,'_blank')
            }
            Swal.close()
          }
        }
      },
      async onCheckLmsJob (courseId, mappingId) {
        console.log(courseId, mappingId)
        Swal.fire({
          text: 'loading...',
          allowOutsideClick: false
        })
        Swal.showLoading()
        const resMapping = await apiGatewayCpmService.getCourseMappingOne(courseId,mappingId,localStorage.getItem("accessToken"))
        if(resMapping.status && resMapping[0].webUrl !== ""){
          window.open(`${resMapping[0].webUrl}`,'_blank')
          Swal.close()
        }else{
          Swal.fire({
            icon: 'info',
            title: 'ระบบกำลังดำเนินการ',
            text: 'อาจใช้เวลาสักครู่ในการประมวลผล',
            showCancelButton: true,
            confirmButtonText: 'ดูสถานะ',
            cancelButtonText: 'ปิด',
            // didDestroy: () => {
            //   this.$router.push(`/courses/${courseId}`)
            // }
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.$router.push(`/courses/${courseId}/lms`)
            }
          })
        }
      },
      getCouseFullSemester (shortSemester) {
        let semesterArr = shortSemester.split("")
        // console.log("semesterArr:",semesterArr)
        return `${semesterArr[0]}/25${semesterArr[1]}${semesterArr[2]}`
      },
      modalAdminSubmit (createData) {
        console.log("createData :>", createData)
        this.onCreateSubmit(createData)
      },
     
    },
  }
</script>
