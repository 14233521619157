<template>
  <v-container fluid>
    <!-- <v-row class="text-left pt-5">
        <v-col cols="12" class="pt-5 pb-1">
            <h1>
                Announcement
            </h1>
        </v-col>  
    </v-row> -->
    <!-- <v-row>
        <v-col>
            <v-carousel height="400">
                <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                    :src="item.src"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                >
                    <v-btn v-if="i === 999" color="#8465c2" class="white--text vertical-horizontal-center" x-large to="/activate-microsoft-teams" id="activate-teams-btn-on-banner">
                        Activate Teams
                    </v-btn>
                </v-carousel-item>
            </v-carousel>
        </v-col>
    </v-row> -->
    
    <admin-components class="" :adminSubjection="adminSubjection" :createStatus="createStatus" @modalAdminSubmit="modalAdminSubmit" :isAdminCMU="isAdminCMU" :ListAllFaculty="ListAllFaculty" @onSelectFac="onSelectFac" @onSelectSemester="onSelectSemester"/>
  </v-container>
</template>

<script>
import AdminComponents from './AdminComponents/AdminComponents'
export default {
    name: 'DashboardAdmin',
    props: {
      adminSubjection: Object,
      createStatus: Object,
      isAdminCMU: Boolean,
      ListAllFaculty: Array,
      
    },
    components: {
        AdminComponents,
    },
    data: () => ({
        items: [
            {
                src: require('../assets/images/cpm-banner-1.png'),
            },
        ],
        regCourses: []
    }),
    created() {
    },
    // watch:{
    //     adminSubjection: function(val, oldVal) {
    //         console.log(val,oldVal)
    //     }
    // },
    methods: {
        onCreateTeamsSubmit (sectionArr, courseId) {
            console.log("My course onCreateTeamsSubmit:",sectionArr)
            console.log("My course onCreateTeamsSubmit:",courseId)
            this.$emit('createTeamsSubmit', sectionArr, courseId)
        },
        onCreateLmsSubmit (createData,courseid) {
            this.$emit('createLmsSubmit', createData, courseid)
        },
        onCheckTeamJob (courseId, mappingId) {
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        onCheckLmsJob (courseId, mappingId) {
            this.$emit('checkLmsJob', courseId, mappingId)
        },
        modalAdminSubmit (createData) {
            this.$emit('modalAdminSubmit', createData)
        },
        onSelectFac(query){
            this.$emit('onSelectFac', query)
        },
        onSelectSemester(query){
            this.$emit('onSelectSemester' , query);
        }
    },
}
</script>
